import React from 'react';
import FacebookIcon from 'mdi-react/FacebookIcon';
import InstagramIcon from 'mdi-react/InstagramIcon';
import YoutubeIcon from 'mdi-react/YoutubeIcon';
import LinkedinIcon from 'mdi-react/LinkedinIcon';
import { appendHTTPS } from '../../util';

const Footer = ({ data }) => {
    return (
        <>
            <div className="social-media">
                {data?.facebook && (
                    <a target="_blank" rel="nofollow noreferrer" href={appendHTTPS(data.facebook)}>
                        <FacebookIcon className="footer-icons" />
                    </a>
                )}
                {data?.instagram && (
                    <a target="_blank" rel="nofollow noreferrer" href={appendHTTPS(data.instagram)}>
                        <InstagramIcon className="footer-icons" />
                    </a>
                )}
                {data?.youtube && (
                    <a target="_blank" rel="nofollow noreferrer" href={appendHTTPS(data.youtube)}>
                        <YoutubeIcon className="footer-icons" />
                    </a>
                )}
                {data?.linkedin && (
                    <a target="_blank" rel="nofollow noreferrer" href={appendHTTPS(data.linkedin)}>
                        <LinkedinIcon className="footer-icons" />
                    </a>
                )}
            </div>
            <div className="country-list">
                <span>Singapore</span>
                <span>Malaysia</span>
                <span>Indonesia</span>
                <span>Philippines</span>
                <span>Thailand</span>
            </div>
        </>
    );
};

export default Footer;
