/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import '../scss/profile.scss';
import Footer from './footer/footer';
import Header from './header/header';
import HorizontalBox from './contact-box/HorizontalBox';
import VerticalBox from './contact-box/VerticalBox';
import { getVirtualCardDetails } from '../api/virtual-card/virtualCardApi';
import { useParams } from 'react-router-dom';
import ErrorPage from './error-page/ErrorPage';
import Loading from '../shared-parts/components/Loading';

const ProfileCard = () => {
    const [virtualCardData, setVirtualCardData] = useState({});
    const [loading, setLoading] = useState(false);
    const { slug } = useParams();
    const fetchCardDetails = async () => {
        setLoading(true);
        try {
            const { data: cardData } = await getVirtualCardDetails(slug);
            setVirtualCardData(cardData?.data);
            setLoading(false);
        } catch (e) {
            console.debug(e);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCardDetails();
    }, []);

    return (
        <div className="container">
            {loading ? (
                <Loading />
            ) : virtualCardData ? (
                <>
                    <Header data={virtualCardData} />
                    <HorizontalBox data={virtualCardData} />
                    <VerticalBox data={virtualCardData} />
                    <Footer data={virtualCardData} />
                </>
            ) : (
                <ErrorPage />
            )}
        </div>
    );
};

export default ProfileCard;
