import React from 'react';
import TelephoneIcon from 'mdi-react/TelephoneIcon';
import WhatsappIcon from 'mdi-react/WhatsappIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import AccountPlusIcon from 'mdi-react/AccountPlusIcon';
import ShareIcon from 'mdi-react/ShareIcon';
import FileSaver from 'file-saver';

const HorizontalBox = ({ data }) => {
    const handleClick = (e) => {
        e.preventDefault();
        var file = new Blob(
            [
                `BEGIN:VCARD
VERSION:3.0
N:${data?.lastName};${data?.firstName};;;
FN:${data?.firstName} ${data?.lastName}
EMAIL;type=INTERNET;type=pref:${data?.email}
TEL;type=CELL;type=VOICE;type=pref:${data.phone}
ADR;type=WORK;type=pref:;;;${''};;;
END:VCARD`,
            ],
            { type: 'text/vcard;charset=utf-8' },
        );
        FileSaver.saveAs(file, `${data.firstName}${data.lastName}.vcf`, true);
    };
    return (
        <div className="contact-box">
            <a href={`tel: ${data.mobile}`}>
                <TelephoneIcon className="contact-icons" />
            </a>
            <a
                target="_blank"
                rel="nofollow noreferrer"
                href={`https://wa.me/${data?.mobile?.replace(/\s/g, '')}`}
            >
                <WhatsappIcon className="contact-icons" />
            </a>
            <a href={`mailto: ${data.email}`}>
                <EmailIcon className="contact-icons" />
            </a>
            <a href="" onClick={handleClick}>
                <AccountPlusIcon className="contact-icons" />
            </a>
        </div>
    );
};

export default HorizontalBox;
