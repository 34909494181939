const ErrorPage = () => {
    return (
        <div className="error-message">
            <div style={{ marginLeft: 0 }} className="logo-box">
                <img
                    height={64}
                    alt="cads logo"
                    src="https://unified.dev.cads.ai/image/Account/cads-logo-white.png"
                />
            </div>
            <h1>No Data Available !</h1>
        </div>
    );
};

export default ErrorPage;
