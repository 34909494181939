import { sendRequest } from '../../shared-parts/base/ApiService';

const getVirtualCardDetails = (slug) => {
    return sendRequest({
        fullUrl: `${process.env.REACT_APP_ROOT_URL}/card/data/${slug}`,
        method: 'GET',
    });
};

export { getVirtualCardDetails };
