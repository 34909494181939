import React from 'react';
// import TelephoneIcon from 'mdi-react/TelephoneIcon';
import WebIcon from 'mdi-react/WebIcon';
import AtIcon from 'mdi-react/AtIcon';
// import DeskphoneIcon from 'mdi-react/DeskphoneIcon';

const VerticalBox = ({ data }) => {
    return (
        <div className="contact-box-vertical">
            {/**TODO */}
            {/* <a href={`tel: ${data.phone}`}>
                <TelephoneIcon />
                <span>{data.phone}</span>
            </a>
            <a href={`tel: ${data.mobile}`}>
                <DeskphoneIcon />
                <span>{data.mobile}</span>
            </a> */}
            <a href={`mailto: ${data.email}`}>
                <AtIcon />
                <span>{data.email}</span>
            </a>
            {data?.website && <a href={data?.website} rel="noopener noreferrer" target="_blank">
                <WebIcon />
                <span>{data?.website}</span>
            </a>}
        </div>
    );
};

export default VerticalBox;
