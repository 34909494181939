import React from "react";

const Header = ({ data }) => {
    return (
        <>
            <div className="logo-box">
                <img
                    height={64}
                    alt="cads logo"
                    src="https://unified.dev.cads.ai/image/Account/cads-logo-white.png"
                />
            </div>
            <div className="user-photo">
                <img
                    src={data?.photoUrl?.startsWith('https') ? data?.photoUrl : "/default-user.png"}
                    alt={data?.firstName}
                />
            </div>
            <div className="user-info-box">
                <h2>
                    {data.firstName} {data.lastName}
                </h2>
                <span className="organization-level">{data?.organizationLevel}</span>
            </div>
        </>
    );
};

export default Header;
