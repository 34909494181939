import './App.css';
import ProfileCard from './components/ProfileCard';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import ErrorPage from './components/error-page/ErrorPage';

function App() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/card/:slug" element={<ProfileCard />} />
                    <Route path="/" element={<Navigate replace to="/card/:slug" />} />
                    <Route
                        path="*"
                        element={
                            <div className="container">
                                <ErrorPage />
                            </div>
                        }
                    />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
