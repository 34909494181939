import axios from 'axios';

const appendQueryParams = (url, params) => {
    if (!!url && !!params) {
        let sanitizedParams = {};
        Object.keys(params)
            .filter((param) => !['', null, undefined].includes(params[param]))
            .forEach((param) => (sanitizedParams[param] = params[param]));
        return `${url}?${new URLSearchParams(sanitizedParams)}`;
    }
    return url;
};

let BASE_URL = process.env.REACT_APP_ROOT_URL;

export function sendRequest(options, endPoint = process.env.REACT_APP_ADMIN_ENDPOINT) {
    let URL = BASE_URL + endPoint;

    const config = {
        headers: options['headers'] ? options['headers'] : { 'Content-Type': 'application/json' },
        url: appendQueryParams(options['fullUrl'] ?? URL + options['url'], options['params']),
        method: options['method'],
        data: options['data'],
    };

    return axios
        .request(config)
        .then((response) => {
            if (response.request.status === 200 && response.data?.status === 200)
                return { data: response.data, isSuccess: true };
            else if (response.status === 200 && response.headers['content-type'] === 'text/csv') {
                return response.data;
            } else if (options?.fullUrl && response.request.status === 200) {
                return response.data;
            } else throw new Error(response.data?.messages?.[0] ?? 'Something went wrong !');
        })
        .catch(async (error) => {
            const status = error?.response?.status ?? -1;
            if (status === 401 || status === 403) {
            } else if (status === 404) {
                // TODO: handle not found error
            } else if (status === 500) {
                // TODO: handle internal server error
            }

            throw error;
        });
}
